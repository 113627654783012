import React from 'react';
import { mainSocket } from 'src/api/ws/MainSocket';
import { chatThunks } from 'src/store/chat/thunks';

import { useAppDispatch } from 'src/store/store';
import { WithAuth } from '../HOK/WithAuth';

const SocketInitialization: React.FC = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    mainSocket.connect().then(() => {
      // temp stub for MVP (unread counter implementation)
      dispatch(
        chatThunks.getChatsThunk({
          filters: {},
        }),
      );
    });
  }, [dispatch]);

  return null;
};

export default WithAuth(SocketInitialization);
