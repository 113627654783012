import type {
  IMessage,
  IChat,
  IParticipant,
  IChatResponse,
  IParticipantResponse,
  IParticipantUser,
  IParticipantAdmin,
  IReportResponse,
  IHelpReport,
  ISupportReport,
  IReport,
  IHelpChat,
  ISupportChat,
  IMessageResponse,
  IOffer,
} from 'src/types';
import type { EntityState } from '@reduxjs/toolkit';
import { isHelpChat, isParticipantAdmin, isSupportReport } from 'src/store/chat/helpers/utils';
import { MediaDataHelper } from 'src/utils/MediaDataHelper';
import { chatsEntityAdapter } from '../entityAdapter';

interface ITransformChatsFromResponse {
  participants: (IParticipantAdmin | IParticipantUser)[];
  chats: (ISupportChat | IHelpChat)[];
}
interface ITransformChatFromResponse {
  participants: (IParticipantAdmin | IParticipantUser)[];
  chat: ISupportChat | IHelpChat;
}

export class ChatDataHelper {
  public static TransformChatFromResponse({
    chatId,
    createdAt,
    participants,
    report,
    lastMessage,
    offer,
  }: IChatResponse): ITransformChatFromResponse {
    const chatParticipants = ChatDataHelper.TransformParticipantsFromResponse(participants);

    const chat: IChat = {
      chatId,
      createdAt,
      lastMessage: lastMessage ? ChatDataHelper.TransformMessageFromResponse(lastMessage) : null,
      messagesIds: [],
      participantsIds: chatParticipants.map(({ participantId }) => participantId),
      report: ChatDataHelper.TransformReportFromResponse(report),
      isFiltered: true,
    };

    if (isHelpChat(chat)) {
      chat.offer = offer as IOffer;
    }

    return {
      chat: chat as ISupportChat | IHelpChat,
      participants: chatParticipants,
    };
  }

  public static TransformChatsFromResponse(chats: IChatResponse[]): ITransformChatsFromResponse {
    return chats.reduce(
      (prev, chatProp) => {
        const { chat, participants } = ChatDataHelper.TransformChatFromResponse(chatProp);

        prev.chats.push(chat);
        prev.participants.push(...participants);

        return prev;
      },
      { chats: [], participants: [] } as ITransformChatsFromResponse,
    );
  }

  public static TransformMessageFromResponse({
    chatId,
    createdAt,
    messageId,
    text,
    mediaItems,
    authorId,
    type,
  }: IMessageResponse): IMessage {
    return {
      authorId,
      chatId,
      createdAt,
      messageId,
      text,
      mediaItems: MediaDataHelper.TransformMediaItemsFromResponse(mediaItems),
      errored: false,
      type,
    };
  }

  public static TransformMessagesFromResponse(messages: IMessageResponse[]) {
    return messages.map(ChatDataHelper.TransformMessageFromResponse);
  }

  public static TransformParticipantsFromResponse(
    participants: IParticipantResponse[],
  ): (IParticipantAdmin | IParticipantUser)[] {
    return participants.map(ChatDataHelper.TransformParticipantFromResponse);
  }

  public static TransformParticipantFromResponse({
    admin,
    user,
    lastViewed,
    participantId,
    participantType,
    unreadCount,
  }: IParticipantResponse) {
    const result: IParticipant = {
      lastViewed,
      participantId,
      participantType,
      unreadCount: unreadCount ?? 0,
    };

    if (isParticipantAdmin(result)) {
      return {
        ...result,
        admin,
      } as IParticipantAdmin;
    }

    return { ...result, user } as IParticipantUser;
  }

  public static TransformReportFromResponse({
    createdAt,
    reportId,
    status,
    type,
    updatedAt,
    offer,
    user,
    assignedTo,
    reason,
  }: IReportResponse): ISupportReport | IHelpReport {
    const result: IReport = {
      createdAt,
      reportId,
      status,
      type,
      updatedAt,
    };

    if (isSupportReport(result)) {
      return result as ISupportReport;
    }

    return {
      ...result,
      offer,
      user,
      assignedTo: assignedTo ?? null,
      reason,
    } as IHelpReport;
  }

  public static chatEntityPartialUpdate(
    extistingState: EntityState<IChat>,
    updates: {
      id: number;
      changes: Partial<IChat>;
    },
  ) {
    const currentExistinValue = extistingState.entities[updates.id];
    if (!currentExistinValue) {
      return;
    }
    const { messagesIds, participantsIds, ...rest } = currentExistinValue;
    chatsEntityAdapter.updateOne(extistingState, {
      id: updates.id,
      changes: {
        ...currentExistinValue,
        ...rest,
        messagesIds: [...(currentExistinValue.messagesIds ?? []), ...(messagesIds ?? [])],
        participantsIds: [
          ...(currentExistinValue.participantsIds ?? []),
          ...(participantsIds ?? []),
        ],
      },
    });
  }

  public static replaceTemporaryMessage(
    extistingState: EntityState<IChat>,
    data: { add: string | number; remove: string | number; id: number },
  ): IChat['messagesIds'] {
    const currentExistinValue = extistingState.entities[data.id];
    if (!currentExistinValue) {
      return [];
    }
    return currentExistinValue.messagesIds?.map((m) => (m === data.remove ? data.add : m)) || [];
  }
}
