import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { PermissionsENUM, routerConstants } from './constants';
import Protector from './Protector';
import Layout from '../../ui/components/Layout/Layout';
import { checkPermission, checkPermissinonForModerators } from './helpers';

const SignInPage = React.lazy(
  () => import('../../pages/SignInPage/SignInPage'),
);
const UsersPage = React.lazy(() => import('../../pages/UsersPage/UsersPage'));
const PermissionNavigate = React.lazy(() => import('./PermissionNavigate'));
const ResetPasswordPage = React.lazy(
  () => import('../../pages/ResetPasswordPage/ResetPasswordPage'),
);
const MeetsPage = React.lazy(() => import('../../pages/MeetsPage/MeetsPage'));
const SupportPage = React.lazy(
  () => import('../../pages/SupportPage/SupportPage'),
);
const ProfilePage = React.lazy(
  () => import('../../pages/ProfilePage/ProfilePage'),
);
const UsersTab = React.lazy(
  () => import('../../ui/components/Tabs/UsersTab/UsersTab'),
);
const ModeratorsTab = React.lazy(
  () => import('../../ui/components/Tabs/ModeratorsTab/ModeratorsTab'),
);

export const router = createBrowserRouter([
  {
    path: routerConstants.login,
    element: (
      <Protector protectType="unprotected" redirectTo={{ unprotected: '/' }}>
        <SignInPage />
      </Protector>
    ),
  },
  {
    path: routerConstants.resetPassword,
    element: (
      <Protector
        protectType="unprotected"
        redirectTo={{ unprotected: routerConstants.login }}
      >
        <ResetPasswordPage />
      </Protector>
    ),
  },
  {
    element: (
      <Protector
        protectType="protected"
        redirectTo={{ protected: routerConstants.login }}
      >
        <Layout />
      </Protector>
    ),
    path: '/',
    children: [
      {
        path: routerConstants.meets,
        element: (
          <Protector
            protectType="protected"
            redirectTo={{ protected: routerConstants.permissionsNavigate }}
            permission={[PermissionsENUM.MEETS_MANAGEMENT]}
            checkPermission={checkPermission}
          >
            <MeetsPage />
          </Protector>
        ),
      },
      {
        path: routerConstants.support,
        element: (
          <Protector
            protectType="protected"
            redirectTo={{ protected: routerConstants.permissionsNavigate }}
            permission={[PermissionsENUM.USERS_SUPPORT]}
            checkPermission={checkPermission}
          >
            <SupportPage />
          </Protector>
        ),
      },
      {
        element: (
          <Protector
            protectType="protected"
            redirectTo={{ protected: routerConstants.permissionsNavigate }}
            permission={[PermissionsENUM.USERS_MANAGEMENT]}
            checkPermission={checkPermission}
          >
            <UsersPage />
          </Protector>
        ),
        children: [
          {
            path: routerConstants.users,
            element: (
              <Protector
                protectType="protected"
                redirectTo={{ protected: routerConstants.permissionsNavigate }}
                permission={[PermissionsENUM.USERS_MANAGEMENT]}
                checkPermission={checkPermission}
              >
                <UsersTab />
              </Protector>
            ),
          },
          {
            path: routerConstants.moderators,
            element: (
              <Protector
                protectType="protected"
                redirectTo={{ protected: routerConstants.permissionsNavigate }}
                permission={[PermissionsENUM.USERS_MANAGEMENT]}
                checkPermission={checkPermissinonForModerators}
              >
                <ModeratorsTab />
              </Protector>
            ),
          },
        ],
      },
      {
        path: routerConstants.profile,
        element: (
          <Protector
            protectType="protected"
            redirectTo={{ protected: routerConstants.permissionsNavigate }}
          >
            <ProfilePage />
          </Protector>
        ),
      },
      {
        path: routerConstants.permissionsNavigate,
        element: (
          <Protector
            protectType="protected"
            redirectTo={{ protected: routerConstants.home }}
          >
            <PermissionNavigate />
          </Protector>
        ),
      },
    ],
  },
  {
    path: '*',
    element: (
      <Protector
        protectType="unprotected"
        redirectTo={{ unprotected: routerConstants.home }}
      >
        <SignInPage />
      </Protector>
    ),
  },
], {
  basename: '/admin',
});
