import React from 'react';
import cn from 'classnames';

import { Text } from '@consta/uikit/Text';
import { useClickOutside } from '@consta/uikit/__internal__/src/hooks/useClickOutside';

import globalStyles from 'src/ui/styles/global.module.scss';
import { Loader } from '@consta/uikit/Loader';
import NotificationItem from './NotificationItem/NotificationItem';
import { NotificationTypeENUM, type NotificationType } from './types';

import styles from './NotificationList.module.scss';
import type { TabItemType } from '../CustomTab/CustomTab';

type PropsType = {
  notifications: NotificationType;
  isLoading?: boolean;
  isOpen?: boolean;
  isDropdown?: boolean;
  ignoreClicksInsideRefs?: React.RefObject<HTMLElement>[];
  setIsOpen?: () => void;
};

const TAB_ITEMS = [
  {
    label: 'All',
    id: 1,
  },
  {
    label: 'Verifications',
    id: 2,
  },
  {
    label: 'Support',
    id: 3,
  },
];

const NotificationList: React.FC<PropsType> = (props) => {
  const [currentTab, setCurrentTabTab] = React.useState<number>(1);

  const listRef = React.useRef<HTMLDivElement | null>(null);
  useClickOutside({
    isActive: props.isOpen,
    ignoreClicksInsideRefs: [listRef, ...(props.ignoreClicksInsideRefs ?? [])],
    handler: props.setIsOpen,
  });

  React.useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    const alreadyContain = document.body.classList.contains(globalStyles['sidebar-open']);

    if (!alreadyContain) {
      document.body.classList.add(globalStyles['sidebar-open']);
    }
    return () => {
      if (!alreadyContain) {
        document.body.classList.remove(globalStyles['sidebar-open']);
      }
    };
  }, [props.isOpen]);

  const handleTabChange = (tab: TabItemType) => {
    setCurrentTabTab(tab.id);
  };

  const list = React.useMemo(() => {
    const kyc = props.notifications.kycs.map((el) => {
      return {
        id: el.user.userId,
        key: `kyc_${el.kycVerificationId}`,
        type: NotificationTypeENUM.kyc,
        label: el.user.name || el.user.phone,
        date: el.updatedAt ? new Date(el.updatedAt) : new Date(),
        description: 'Verification request',
      };
    });

    const reports = props.notifications.reports.map((el) => {
      return {
        id: el.chat?.chatId || el.reportId,
        key: `rep_${el.reportId}`,
        type: NotificationTypeENUM.report,
        label: el?.user?.name || el?.user?.phone || 'Unknown',
        date: el.updatedAt ? new Date(el.updatedAt) : new Date(),
        description: 'Contact support',
      };
    });

    const all = [...kyc, ...reports].sort((a, b) => {
      return b.date.getTime() - a.date.getTime();
    });

    return {
      kyc,
      reports,
      all,
    };
  }, [props.notifications]);

  return (
    <div
      ref={listRef}
      className={cn(styles.containter__content, {
        [styles.container]: props.isDropdown,
        [styles.visible]: props.isOpen,
      })}
    >
      {props.isDropdown && (
        <Text size="m" weight="semibold">
          Notifications
        </Text>
      )}
      {props.isLoading ? (
        <div className={styles.loader}>
          <Loader size="s" />
        </div>
      ) : (
        <div
          className={cn({
            [styles.list]: !props.isDropdown,
          })}
        >

          <div className={styles.tabs}>
            {TAB_ITEMS.map((item, idx) => (
              <div className={styles.tab} key={idx} onClick={() => handleTabChange(item)}>
                <div className={styles.tab_container}>
                  <Text className={cn(styles.tab__content, {
                    [styles.active_text]: item.id === currentTab,
                  })}
                  >{item.label}
                  </Text>
                </div>
                <div className={cn({
                  [styles.active]: item.id === currentTab,
                })
                }
                />
              </div>
            ))}
          </div>

          <div className={cn({ [styles.hidden]: currentTab !== 1 })}>
            {list.all.map((item) => {
              return (
                <NotificationItem {...item} key={item.key} />
              );
            })}
          </div>

          <div className={cn({ [styles.hidden]: currentTab !== 2 })}>
            {list.kyc.map((item) => {
              return (
                <NotificationItem {...item} key={item.key} />
              );
            })}
          </div>

          <div className={cn({ [styles.hidden]: currentTab !== 3 })}>
            {list.reports.map((item) => {
              return (
                <NotificationItem {...item} key={item.key} />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationList;
