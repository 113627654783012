import type { IKYCVerification, IReport } from 'src/types';

export type NotificationItemType = {
  label: string;
  description: string;
  date: Date;
  type: NotificationTypeENUM;
  id: number;
};

export type NotificationType = { kycs: IKYCVerification[]; reports: IReport[] };

export enum NotificationTypeENUM {
  kyc = 'kyc',
  report = 'report',
}
