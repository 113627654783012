import React from 'react';
import { Link } from 'react-router-dom';

import { IconSettings } from '@consta/icons/IconSettings';
import { ReactComponent as Bfriendz } from 'src/ui/assets/images/Bfriendz.svg';
import { ReactComponent as BFLogo } from 'src/ui/assets/images/BFLogo.svg';

import { routerConstants } from '../../../core/routes/constants';

import styles from './Topbar.module.scss';
import ProfilePreview from './ProfilePreview/ProfilePreview';
import Notifications from '../Notifications/Notifications';

type PropsType = {
  name?: string;
  role: string;
  userAvatar?: string | null;
};

const Topbar: React.FC<PropsType> = (props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  return (
    <div className={styles.topbar} ref={ref}>
      <div className={styles.topbar__logo}>
        <BFLogo />
        <Bfriendz />
      </div>
      <div className={styles.topbar__container}>
        <Notifications reference={ref} />

        <Link
          to={routerConstants.profile}
          className={styles.container__button_settings}
         >
          <IconSettings />
        </Link>

        <ProfilePreview
          name={props.name}
          role={props.role}
          userAvatar={props.userAvatar}
        />
      </div>
    </div>
  );
};

export default Topbar;
