// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
}
html,
body {
  height: 100%;
  width: 100%;
  display: flex;
}
.App {
  width: 100%;
}
.MixFocus,
.MixFocus:not(.MixFocus_before):focus,
.MixFocus:not(.MixFocus_before):focus:hover {
  box-shadow: none;
  animation: none;
}

div.ListBox {
  background-color: white;
}

div.ListItem:hover {
  background-color: white;
  color: var(--color-base-content);
}
div.ListItem:active {
  background-color: white;
  color: var(--color-base-content);
}
.ListItem_disabled.Text:hover {
  color: var(--color-control-typo-disable);
}

div.ListItem_checked.Text {
  color: var(--color-base-content);
}

div.ListItem {
  z-index: 9999;
  background-color: white;
}

div.ListBox {
  z-index: 9999;
}

.MixFocus {
  --mix-focus-color-one: var(--color-control-bg-focus);
  --mix-focus-color-two: var(--color-control-bg-active);
  --mix-focus-animation: Animation--MixFocus 0.5s ease-in;
}

.Sidebar {
  z-index: 999;
}

div.ListBox {
  background-color: white;
}

.Modal {
  z-index: 9999;
}

.slick-track {
  display: flex !important;
}

.slick-active.slick-active.slick-active {
  background-color: rgba(255, 255, 255, 1);
}`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;AACf;AACA;;EAEE,YAAY;EACZ,WAAW;EACX,aAAa;AACf;AACA;EACE,WAAW;AACb;AACA;;;EAGE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,gCAAgC;AAClC;AACA;EACE,uBAAuB;EACvB,gCAAgC;AAClC;AACA;EACE,wCAAwC;AAC1C;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,oDAAoD;EACpD,qDAAqD;EACrD,uDAAuD;AACzD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n#root {\n  height: 100%;\n  width: 100%;\n  display: flex;\n}\nhtml,\nbody {\n  height: 100%;\n  width: 100%;\n  display: flex;\n}\n.App {\n  width: 100%;\n}\n.MixFocus,\n.MixFocus:not(.MixFocus_before):focus,\n.MixFocus:not(.MixFocus_before):focus:hover {\n  box-shadow: none;\n  animation: none;\n}\n\ndiv.ListBox {\n  background-color: white;\n}\n\ndiv.ListItem:hover {\n  background-color: white;\n  color: var(--color-base-content);\n}\ndiv.ListItem:active {\n  background-color: white;\n  color: var(--color-base-content);\n}\n.ListItem_disabled.Text:hover {\n  color: var(--color-control-typo-disable);\n}\n\ndiv.ListItem_checked.Text {\n  color: var(--color-base-content);\n}\n\ndiv.ListItem {\n  z-index: 9999;\n  background-color: white;\n}\n\ndiv.ListBox {\n  z-index: 9999;\n}\n\n.MixFocus {\n  --mix-focus-color-one: var(--color-control-bg-focus);\n  --mix-focus-color-two: var(--color-control-bg-active);\n  --mix-focus-animation: Animation--MixFocus 0.5s ease-in;\n}\n\n.Sidebar {\n  z-index: 999;\n}\n\ndiv.ListBox {\n  background-color: white;\n}\n\n.Modal {\n  z-index: 9999;\n}\n\n.slick-track {\n  display: flex !important;\n}\n\n.slick-active.slick-active.slick-active {\n  background-color: rgba(255, 255, 255, 1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
