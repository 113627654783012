import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import adminApi from 'src/api/adminApi';
import type { ResponseWithPaginationType } from 'src/api/http/http.types';
import type { IAdmin, IRole } from 'src/types';
import { showErrorMessageByCode } from 'src/utils/handleValidationError';
import ShowSuccessToast from 'src/utils/showToast/ShowSuccessToast';

const getAdminsListThunk = createAsyncThunk<
  ResponseWithPaginationType<{ users: IAdmin[] }>,
  { page?: number; s?: string; role?: number }
>('admins/getAllAdmins', async ({ page = 1, s, role }, { rejectWithValue }) => {
  try {
    const response = await adminApi.getAllAdmins({ page, s, role });
    return response.data;
  } catch (err) {
    showErrorMessageByCode(err, {
      401: 'Unauthorized',
      404: 'Failed to get admins. Please, try again later',
    });
    return rejectWithValue(err);
  }
});

const getAdminByIdThunk = createAsyncThunk<IAdmin, { id: number }>(
  'admins/getAdminById',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await adminApi.getAdminById(id);
      return response.data;
    } catch (err) {
      showErrorMessageByCode(err, {
        401: 'Unauthorized',
        404: 'Failed to get admin. Please, try again later',
      });
      return rejectWithValue(err);
    }
  },
);

const createAdminThunk = createAsyncThunk<
  IAdmin,
  { email: string; password: string; roleId: number }
>('admins/createAdmin', async (data, { rejectWithValue }) => {
  try {
    const response = await adminApi.createAdmin(data);
    return response.data;
  } catch (err) {
    showErrorMessageByCode(err, {
      401: 'Unauthorized',
      403: 'Only super admins allowed to create admins',
      404: 'Failed to create admin or Role not found',
      409: 'Email already exists',
    });
    return rejectWithValue(err);
  }
});

const getAdminRolesListThunk = createAsyncThunk<IRole[], void>(
  'admins/getAdminRolesList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminApi.getAdminRoles();
      return response.data;
    } catch (err) {
      showErrorMessageByCode(err, {
        401: 'Unauthorized',
        403: 'You are not allowed to perform this action',
      });
      return rejectWithValue(err);
    }
  },
);

const updateAdminRoleThunk = createAsyncThunk<IAdmin, { roleId: number; adminId: number }>(
  'admins/updateAdminRole',
  async ({ roleId, adminId }, { rejectWithValue }) => {
    try {
      const response = await adminApi.updateAdminRole(roleId, adminId);
      ShowSuccessToast('Role updated successfully');
      return response.data;
    } catch (err) {
      showErrorMessageByCode(err, {
        401: 'Unauthorized',
        403: 'You are not allowed to perform this action',
        404: 'Role or user not found',
        409: "You can't change the role of the last super admin. There should be at least one Super Admin left",
      });
      return rejectWithValue(err);
    }
  },
);

const deleteAdminById = createAsyncThunk<undefined, { adminId: number }>(
  'admins/deleteAdminById',
  async ({ adminId }, { rejectWithValue }) => {
    try {
      await adminApi.deleteAdminById(adminId);
    } catch (err) {
      showErrorMessageByCode(err, {
        401: 'Unauthorized',
        403: (err as AxiosError<{ message: string }>).response?.data?.message || 'Only Super Admins can delete other admins',
        404: 'User not found',
      });
      return rejectWithValue(err);
    }
  },
);

export const adminsThunks = {
  getAdminsListThunk,
  getAdminByIdThunk,
  createAdminThunk,
  getAdminRolesListThunk,
  updateAdminRoleThunk,
  deleteAdminById,
};
