import React from 'react';
import cn from 'classnames';

import { Button } from '@consta/uikit/Button';
import { IconRing } from '@consta/icons/IconRing';

import adminApi from 'src/api/adminApi';
import NotificationList from '../Notifications/NotificationList';

import styles from './Notifications.module.scss';
import global from '../../styles/global.module.scss';
import type { NotificationType } from './types';

type PropsType = {
  reference: React.MutableRefObject<HTMLDivElement | null>;
};

const Notifications: React.FC<PropsType> = (props) => {
  const [notifications, setNotifications] = React.useState<NotificationType>({
    kycs: [], reports: [],
  });
  const [isLoading, setIsLoading] = React.useState<boolean | undefined>(undefined);
  const [isNotificationsOpen, setIsNotificationsOpen] = React.useState(false);

  const loadNotifications = async () => {
    setIsLoading(true);
    const all = await adminApi.getNotificationsList();
    setNotifications(all.data);
    setIsLoading(false);
  };

  const toggleNotifications = async () => {
    const current = isNotificationsOpen;
    setIsNotificationsOpen(!isNotificationsOpen);
    if (!current) {
      await loadNotifications();
    }
  };

  return (
    <>
      <Button
        className={cn(styles.container__button_notification, {
          [global.visibleBadge]: isLoading === undefined ||
            notifications.kycs.length || notifications.reports.length,
        })}
        iconRight={IconRing}
        onlyIcon
        view="clear"
        onClick={toggleNotifications}
      />
      <NotificationList
        isDropdown
        ignoreClicksInsideRefs={[props.reference]}
        notifications={notifications}
        isOpen={isNotificationsOpen}
        setIsOpen={toggleNotifications}
        isLoading={isLoading}
      />
    </>
  );
};

export default Notifications;
