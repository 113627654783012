import React from 'react';
import { Outlet } from 'react-router-dom';
import type { IParticipantAdmin } from 'src/types';
import { ParticipantRoleENUM } from 'src/types';
import SideBar from '../Sidebar/Sidebar';
import {
  type ISidebarField,
  sidebarFields as originSidebarFields,
} from '../Sidebar/sidebarFields';

import styles from './Layout.module.scss';
import { useAppSelector } from '../../../store/store';
import { routerConstants } from '../../../core/routes/constants';
import Topbar from '../Topbar/Topbar';

const Layout = () => {
  const admin = useAppSelector(({ main }) => main.admin);
  const participants = useAppSelector(({ supportChat }) => supportChat.participants.entities);
  const sidebarFields = React.useMemo(() => {
    let totalUnread = 0;

    const unread = Object.values(participants).filter((p) => {
      const hasUnreaded = p?.participantType === ParticipantRoleENUM.ADMIN &&
      (p as IParticipantAdmin).admin?.adminId === admin?.adminId && p.unreadCount > 0;

      if (hasUnreaded) {
        totalUnread += p.unreadCount;
      }

      return hasUnreaded;
    });

    return originSidebarFields.map((item): ISidebarField => {
      if (item.href === routerConstants.users) {
        return {
          ...item,
        };
      }

      if (item.href === routerConstants.support) {
        const result = {
          ...item,
          hasUnreadMessage: !!unread.length,
        };

        if (totalUnread > 0) {
          result.additionalInfo = String(totalUnread);
        }

        return result;
      }
      return item;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  return (
    <div className={styles.container}>
      <Topbar
        name={admin?.name}
        role={admin!.role.name}
        userAvatar={admin?.avatar?.link}
      />
      <SideBar sidebarFields={sidebarFields} />
      <main>
        <React.Suspense>
          <Outlet />
        </React.Suspense>
      </main>
    </div>
  );
};

export default Layout;
