import { createSelector } from '@reduxjs/toolkit';
import type { IAdmin, IParticipant, IParticipantAdmin, IParticipantUser } from 'src/types';
import { isParticipantAdmin } from 'src/store/chat/helpers/utils';
import { adminSelector } from 'src/store/mainSlice/selectors';
import type { AppStateType } from '../store';
import {
  chatsEntityAdapter,
  messagesEntityAdapter,
  participantsEntityAdapter,
} from './entityAdapter';

const chatEntitySelectors = chatsEntityAdapter.getSelectors(
  (store: AppStateType) => store.supportChat.chats,
);

const participantsEntitySelectors = participantsEntityAdapter.getSelectors(
  (store: AppStateType) => store.supportChat.participants,
);

const messagesEntitySelectors = messagesEntityAdapter.getSelectors(
  (store: AppStateType) => store.supportChat.messages,
);

const getSupportPageSelector = createSelector(
  (store: AppStateType) => store,
  (store) => store.supportChat,
);

const chatListSelector = createSelector(chatEntitySelectors.selectAll, (chats) => {
  return chats;
});

const getChatIdsSelector = createSelector(chatListSelector, (list) => {
  return list.map((item) => item.chatId);
});

const getSelectedChatIdSelector = createSelector(
  getSupportPageSelector,
  (supportpage) => supportpage.selectedChatId,
);

const selectChatByIdSelector = createSelector(chatEntitySelectors.selectById, (chatInList) => {
  return chatInList ?? null;
});

const getChatMessageIdsSelector = createSelector(
  selectChatByIdSelector,
  (chat) => chat?.messagesIds ?? [],
);

const getMessagesByChatIdSelector = createSelector(
  getChatMessageIdsSelector,
  messagesEntitySelectors.selectAll,
  (chatMessagesIds, messages) => {
    const chatMessagesIdsSet = new Set(chatMessagesIds);

    return messages.filter((message) => chatMessagesIdsSet.has(message.messageId));
  },
);

const chatParticipantsIdsSelector = createSelector(
  selectChatByIdSelector,
  (chat) => chat?.participantsIds ?? [],
);

const chatParticipantsSelector = createSelector(
  participantsEntitySelectors.selectEntities,
  chatParticipantsIdsSelector,
  (_: AppStateType, chatId: number) => chatId,
  (participants, participantsIds) => {
    const chatParticipants = participantsIds
      ?.map((p) => participants[p])
      .filter((p): p is IParticipant => !!p);

    return chatParticipants;
  },
);

const allParticipantsUsersInChatSelector = createSelector(
  chatParticipantsSelector,
  (participants: IParticipant[]) => {
    return participants.filter(
      (participant) => !isParticipantAdmin(participant),
    ) as IParticipantUser[];
  },
);

const allParticipantsAdminsInChatSelector = createSelector(
  chatParticipantsSelector,
  (participants: IParticipant[] | null) => {
    return (participants ?? []).filter((participant) => {
      return isParticipantAdmin(participant);
    }) as IParticipantAdmin[];
  },
);

// admins participants ids
const allParticipantsAdminsIdsInChatSelector = createSelector(
  allParticipantsAdminsInChatSelector,
  (admins: IParticipantAdmin[]) => admins.map((admin) => admin.participantId),
);

const meAsParticipantChatSelector = createSelector(
  adminSelector,
  chatParticipantsSelector,
  (me: IAdmin | null, participants: IParticipant[]) => {
    if (!me || !participants.length) return null;

    return (
      participants.filter((participant) => {
        if (isParticipantAdmin(participant)) {
          return participant.admin.adminId === me.adminId;
        }

        return false;
      })[0] ?? null
    );
  },
);

const myUnreadMessagesCountSelector = createSelector(
  meAsParticipantChatSelector,
  (meAsParticipant: IParticipant | null) => {
    if (!meAsParticipant) return 0;

    return meAsParticipant.unreadCount;
  },
);

const chatListLengthSelector = createSelector(
  chatEntitySelectors.selectAll,
  (state) => state.length,
);

export const chatSelectors = {
  chatEntitySelectors,
  participantsEntitySelectors,
  messagesEntitySelectors,

  getSelectedChatIdSelector,
  meAsParticipantChatSelector,
  allParticipantsUsersInChatSelector,
  allParticipantsAdminsInChatSelector,
  allParticipantsAdminsIdsInChatSelector,
  myUnreadMessagesCountSelector,
  chatListSelector,
  chatParticipantsSelector,
  chatListLengthSelector,
  getMessagesByChatIdSelector,
  getChatIdsSelector,
};
