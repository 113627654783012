import React from 'react';
import { mainSocket } from 'src/api/ws/MainSocket';
import { SocketEventsENUM } from 'src/api/ws/socketEvents';
import { chatActions } from 'src/store/chat/slice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import type {
  IChatResponse,
  IMessageResponse,
  IParticipantResponse,
  IReportResponse,
} from 'src/types';

export const useChatWSListeners = () => {
  const dispatch = useAppDispatch();
  const isSocketConnected = useAppSelector(({ main }) => main.socketConnectionStatus);

  React.useEffect(() => {
    if (!isSocketConnected) return;

    const subscribers = [
      mainSocket.subscribe<{
        message: IMessageResponse;
      }>(SocketEventsENUM.on_chatNewMessage, (data) => {
        dispatch(chatActions.onNewMessage(data.payload.message));
      }),

      mainSocket.subscribe<{ chat: IChatResponse }>(
        SocketEventsENUM.on_requestsNewRequest,
        (data) => {
          dispatch(chatActions.onIncomingNewRequest(data.payload));
        },
      ),

      mainSocket.subscribe<{
        chatId: number;
        report: IReportResponse;
        participant?: IParticipantResponse;
      }>(SocketEventsENUM.on_requestsStatusChange, (data) => {
        dispatch(chatActions.onRequestStatusChange(data.payload));
      }),

      mainSocket.subscribe<{ participant: IParticipantResponse }>(
        SocketEventsENUM.on_chatUpdateParticipant,
        (data) => {
          dispatch(chatActions.onUpdateParticipant(data.payload));
        },
      ),
    ];

    return () => {
      subscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [dispatch, isSocketConnected]);
};
