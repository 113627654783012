import type { FindAndCountMetaType, IAdmin, IRole } from '../types';
import { PathENUM } from './constans';
import http from './http';

const updateAdmin = async (payload: {
  data: { email?: string; name?: string | null; phone?: string | null };
  adminId?: number;
}) => {
  const response = http.patch<IAdmin>(`${PathENUM.ADMIN}/${payload?.adminId}`, payload.data);

  return response;
};

const updateAvatar = async (data: { file: string | ArrayBuffer; name: string }) => {
  const response = http.patch<IAdmin>(`${PathENUM.ADMIN}/avatar`, data);

  return response;
};

const createAdmin = (data: { email: string; password: string; roleId: number }) => {
  return http.post<IAdmin>(PathENUM.ADMIN, data);
};

const getAdminRoles = () => {
  return http.get<IRole[]>(`${PathENUM.ROLES}`);
};

const getAllAdmins = (params: { page: number; s?: string; role?: number }) => {
  return http.get<{ users: IAdmin[]; pagination: FindAndCountMetaType }>(`${PathENUM.ADMIN}`, {
    params,
  });
};

const getAdminById = (adminId: number) => {
  return http.get<IAdmin>(`${PathENUM.ADMIN}/${adminId}`);
};

const updateAdminRole = (roleId: number, adminId: number) => {
  return http.patch<IAdmin>(`${PathENUM.ADMIN}/${adminId}/role`, { roleId });
};

const deleteAdminById = (adminId: number) => {
  return http.delete(`${PathENUM.ADMIN}/${adminId}`);
};

const getNotificationsList = () => {
  return http.get(`${PathENUM.ADMIN_NOTIFICATIONS}`);
};

export default {
  updateAvatar,
  createAdmin,
  getAdminRoles,
  getAdminById,
  getAllAdmins,
  updateAdminRole,
  updateAdmin,
  deleteAdminById,
  getNotificationsList,
};
