import type { IUser, FindAndCountMetaType, IOffer } from 'src/types';

import http from './http';
import { PathENUM } from './constans';
import type { ResponseWithPaginationType } from './http/http.types';
import type { UsersDataType } from '../utils/excel/types';

export type GetUsersListParamsType = {
  st?: 'ban' | 'block' | string;
  ver?: 'verified' | 'unverified' | 'pending' | string;
  s?: string;
  page?: number;
};

export type UserOffersResponseType = {
  offers: IOffer[];
  pagination: FindAndCountMetaType;
};

const getAllUsers = (params: GetUsersListParamsType) => {
  return http.get<ResponseWithPaginationType<{ users: IUser[] }>>(PathENUM.USERS, { params });
};

const getUserById = (id: number) => {
  return http.get<IUser>(`${PathENUM.USERS}/${id}`);
};

const getUserOffers = (params: { page: number; id: number }) => {
  const { id, ...requestParams } = params;
  return http.get<ResponseWithPaginationType<{ offers: IOffer[] }>>(`${PathENUM.USERS}/${id}${PathENUM.OFFERS}`, { params: requestParams });
};
const getUserMeetings = (params: { page: number; id: number }) => {
  const { id, ...requestParams } = params;
  return http.get<ResponseWithPaginationType<{ offers: IOffer[] }>>(`${PathENUM.USERS}/${id}${PathENUM.MEETS}`, { params: requestParams });
};

const getExportData = () => {
  return http.get<UsersDataType[]>(`${PathENUM.USERS}/export/excel`);
};

export default {
  getUserById,
  getAllUsers,
  getUserOffers,
  getUserMeetings,
  getExportData,
};
