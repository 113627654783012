// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notifications_container__button_notification__cbkoT {
  border-radius: 6px;
  padding: 4px;
}
.Notifications_container__button_notification__cbkoT > span {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/Notifications/Notifications.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;AAEJ","sourcesContent":[".container__button_notification {\n  border-radius: 6px;\n  padding: 4px;\n  & > span {\n    width: 24px;\n    height: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container__button_notification": `Notifications_container__button_notification__cbkoT`
};
export default ___CSS_LOADER_EXPORT___;
