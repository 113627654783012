export enum PathENUM {
  AUTH = '/auth',
  USERS = '/users',
  ADMIN = '/admin',
  ROLES = '/roles',
  OFFERS = '/offers',
  OFFER = '/offer',
  MEETS = '/meets',
  PENALTY = '/penalty',
  BAN = '/ban',
  BLOCK = '/block',
  UNBAN = '/unban',
  UNBLOCK = '/unblock',
  KYC = '/kyc',
  ACCEPT = '/accept',
  REJECT = '/reject',
  REQUEST = '/request',
  CANCEL = '/cancel',
  MEDIA_ITEM = '/media-item',
  UPLOAD = '/upload',
  ADMIN_NOTIFICATIONS = '/admin-notifications',
}
