import React from 'react';
import qs from 'query-string';
import { mainSocket } from 'src/api/ws/MainSocket';
import { chatThunks } from 'src/store/chat/thunks';

import { useAppDispatch } from 'src/store/store';
import { transformSupportParamsToRequest } from 'src/pages/SupportPage/utils/filters';
import { useChatWSListeners } from 'src/pages/SupportPage/hooks/useChatWSListeners';
import { WithAuth } from '../HOK/WithAuth';

const SocketInitialization: React.FC = () => {
  const dispatch = useAppDispatch();
  useChatWSListeners();

  React.useEffect(() => {
    const queryParams = qs.parse(window.location.search);

    mainSocket.connect().then(() => {
      dispatch(
        chatThunks.getChatsThunk({
          filters: transformSupportParamsToRequest(queryParams),
        }),
      );
    });
  }, [dispatch]);

  return null;
};

export default WithAuth(SocketInitialization);
