import React from 'react';
import { Outlet } from 'react-router-dom';
import type { IParticipantAdmin } from 'src/types';
import { ParticipantRoleENUM } from 'src/types';
import SideBar from '../Sidebar/Sidebar';
import {
  type ISidebarField,
  sidebarFields as originSidebarFields,
} from '../Sidebar/sidebarFields';

import styles from './Layout.module.scss';
import { useAppSelector } from '../../../store/store';
import { routerConstants } from '../../../core/routes/constants';
import Topbar from '../Topbar/Topbar';

const Layout = () => {
  const admin = useAppSelector(({ main }) => main.admin);
  const participants = useAppSelector(({ supportChat }) => supportChat.participants.entities);

  const sidebarFields = React.useMemo(() => {
    const unread = Object.values(participants).find((p) => {
      return p?.participantType === ParticipantRoleENUM.ADMIN &&
        (p as IParticipantAdmin).admin.adminId === admin?.adminId && p.unreadCounts > 0;
    });

    return originSidebarFields.map((item): ISidebarField => {
      if (item.href === routerConstants.users) {
        return {
          ...item,
        };
      }

      if (item.href === routerConstants.support) {
        return {
          ...item,
          hasUnreadMessage: !!unread,
        };
      }
      return item;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <Topbar
        name={admin?.name}
        role={admin!.role.name}
        userAvatar={admin?.avatar?.link}
      />
      <SideBar sidebarFields={sidebarFields} />
      <main>
        <React.Suspense>
          <Outlet />
        </React.Suspense>
      </main>
    </div>
  );
};

export default Layout;
