import type { IAdmin, IOffer, IUser } from 'src/types';
import type { IMedia, IMediaResponse, ITempMedia } from 'src/types/mediaItemType';

export enum ReportStatusENUM {
  pending = 'pending',
  closed = 'closed',
  inProgress = 'in_progress',
}

export enum ReportTypeENUM {
  SUPPORT = 'support',
  REPORT = 'report',
}

export enum ParticipantRoleENUM {
  USER = 'user',
  ADMIN = 'admin',
}

export interface IParticipant {
  participantId: number;
  participantType: ParticipantRoleENUM;
  lastViewed: string | null;
  unreadCount: number;
}

// if ParticipantRoleENUM.admin
export interface IParticipantAdmin extends IParticipant {
  admin: IAdmin;
}
// if ParticipantRoleENUM.user
export interface IParticipantUser extends IParticipant {
  user: IUser;
}

export interface IParticipantResponse {
  authorId: number;
  chatId: number;
  createdAt: string;
  deletedAt: string | null;
  participantId: number;
  participantType: ParticipantRoleENUM;
  unreadCount: number;
  updatedAt: string;
  lastViewed: string | null;
  author: IUser | IAdmin;
  user?: IUser;
  admin?: IAdmin;
}

export interface IChat {
  chatId: number;
  createdAt: string;
  report: IReport;
  lastMessage: (IMessage | ITempMessage) | null;
  messagesIds: IBaseMessage['messageId'][];
  participantsIds: IParticipant['participantId'][];
  isFiltered: boolean;
}

export interface ISupportChat extends IChat {
  report: ISupportReport;
}

export interface IHelpChat extends IChat {
  report: IHelpReport;
  offer: IOffer;
}

export interface IChatResponse {
  chatId: number;
  createdAt: string;
  participants: IParticipantResponse[];
  report: IReportResponse;
  offer?: IOffer;
  lastMessage?: IMessageResponse;
}

export interface IReport {
  reportId: number;
  status: ReportStatusENUM;
  type: ReportTypeENUM;
  createdAt: string;
  updatedAt: string;
  user?: IUser;
  chat?: IChat;
}
export interface ISupportReport extends IReport {
  type: ReportTypeENUM.SUPPORT;
}
export interface IHelpReport extends IReport {
  type: ReportTypeENUM.REPORT;
  offer: IOffer;
  user: IUser;
  assignedTo: IAdmin | null;
  reason: string;
}

export interface IReportResponse {
  reportId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  closedAt: string | null;
  reason: string | null;
  status: ReportStatusENUM;
  type: ReportTypeENUM;
  user?: IUser;
  assignedTo?: IAdmin;
  offer?: IOffer;
}

export interface IBaseMessage {
  messageId: number | string;
  chatId: number;
  // participant author id
  authorId: number;
  text: string;
  createdAt: string;
  mediaItems: (IMedia | ITempMedia)[];
  errored: boolean;
  type: MessageTypeENUM;
}

export interface IMessage extends IBaseMessage {
  messageId: number;
  mediaItems: IMedia[];
}

export interface ITempMessage extends IBaseMessage {
  messageId: string;
  mediaItems: ITempMedia[];
}

export interface IMessageResponse {
  messageId: number;
  chatId: number;
  // participant author id
  authorId: number;
  text: string;
  createdAt: string;
  media?: unknown;
  readed: boolean;
  mediaItems?: IMediaResponse[];
  type: MessageTypeENUM;
}

export enum MessageTypeENUM {
  DEFAULT = 'DEFAULT',
  SERVICE_RESCHEDULING_REJECT = 'SERVICE_RESCHEDULING_REJECT',
  SERVICE_RESCHEDULING_ACCEPT = 'SERVICE_RESCHEDULING_ACCEPT',
  SERVICE_BAN_FIRST_MESSAGE = 'SERVICE_BAN_FIRST_MESSAGE',
  SERVICE_BLOCK_FIRST_MESSAGE = 'SERVICE_BLOCK_FIRST_MESSAGE',
}
