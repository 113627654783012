// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationItem_container__Eb2kQ {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  gap: 10px;
  align-items: baseline;
  cursor: pointer;
}
.NotificationItem_container__Eb2kQ:hover {
  background-color: rgba(138, 137, 147, 0.1019607843);
}

.NotificationItem_container__row__YxABk {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.NotificationItem_text__lable__PSisv {
  font-size: var(--size-text-s);
  font-weight: var(--font-weight-text-medium);
  line-height: var(--line-height-text-s);
}

.NotificationItem_text__description__Z0eE2 {
  color: var(--color-base-font-label);
  font-size: var(--size-text-m);
  font-weight: var(--font-weight-text-semibold);
  line-height: var(--line-height-text-s);
  letter-spacing: -0.34px;
}

.NotificationItem_verification_badge__FM62C {
  background-color: var(--color-base-content);
  color: white;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.NotificationItem_pending_badge__-MlFT {
  background-color: var(--color-base-alert);
  color: white;
  --badge-text-size: 10px;
  --badge-size: 20px;
  --color-typo-primary: white;
  --control-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/Notifications/NotificationItem/NotificationItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,SAAA;EACA,qBAAA;EACA,eAAA;AACF;AACE;EACE,mDAAA;AACJ;;AAGA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAAF;;AAGA;EACE,6BAAA;EACA,2CAAA;EACA,sCAAA;AAAF;;AAGA;EACE,mCAAA;EACA,6BAAA;EACA,6CAAA;EACA,sCAAA;EACA,uBAAA;AAAF;;AAGA;EACE,2CAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,yCAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,2BAAA;EACA,qBAAA;AAAF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  padding: 15px 0;\n  gap: 10px;\n  align-items: baseline;\n  cursor: pointer;\n\n  &:hover {\n    background-color: #8A89931A;\n  }\n}\n\n.container__row {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.text__lable {\n  font-size: var(--size-text-s);\n  font-weight: var(--font-weight-text-medium);\n  line-height: var(--line-height-text-s);\n}\n\n.text__description {\n  color: var(--color-base-font-label);\n  font-size: var(--size-text-m);\n  font-weight: var(--font-weight-text-semibold);\n  line-height: var(--line-height-text-s);\n  letter-spacing: -0.34px\n}\n\n.verification_badge {\n  background-color: var(--color-base-content);\n  color: white;\n  border-radius: 4px;\n  font-size: 10px;\n  font-weight: 600;\n  line-height: 20px;\n  text-align: left;\n}\n\n.pending_badge {\n  background-color: var(--color-base-alert);\n  color: white;\n  --badge-text-size: 10px;\n  --badge-size: 20px;\n  --color-typo-primary: white;\n  --control-radius: 4px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NotificationItem_container__Eb2kQ`,
	"container__row": `NotificationItem_container__row__YxABk`,
	"text__lable": `NotificationItem_text__lable__PSisv`,
	"text__description": `NotificationItem_text__description__Z0eE2`,
	"verification_badge": `NotificationItem_verification_badge__FM62C`,
	"pending_badge": `NotificationItem_pending_badge__-MlFT`
};
export default ___CSS_LOADER_EXPORT___;
