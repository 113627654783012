import { SupportPageFiltersENUM } from 'src/pages/SupportPage/SupportPageTypes';
import type { SupportPageSearchParamsType } from 'src/pages/SupportPage/SupportPageTypes';
import { ReportStatusENUM } from 'src/types';
import helpers from 'src/utils/helpers';

export type SupportFiltersStatusStateType = Record<ReportStatusENUM, boolean>;
export interface IRequestFiltersChats {
  statuses?: ReportStatusENUM[];
  s?: string;
}

export const parseStatusesFromParams = (
  statuses: SupportPageSearchParamsType[SupportPageFiltersENUM.STATUSES],
): SupportFiltersStatusStateType => {
  const spliited = statuses?.split(',') ?? [];
  const result = Object.values(ReportStatusENUM).reduce((prev, value) => {
    return {
      ...prev,
      [value]: spliited.includes(value),
    };
  }, {} as SupportFiltersStatusStateType);

  return result;
};

export const transformSupportParamsToRequest = (
  params: SupportPageSearchParamsType,
): IRequestFiltersChats => {
  const parsedStatusesFilters = parseStatusesFromParams(params[SupportPageFiltersENUM.STATUSES]);
  const statuses = transformStatusesToRequest(parsedStatusesFilters);

  return {
    statuses,
    s: params[SupportPageFiltersENUM.SEARCH],
  };
};

export const transformStatusesToRequest = (statuses: SupportFiltersStatusStateType) => {
  return helpers.getObjectKeys(statuses).filter((key) => statuses[key]);
};
