import React from 'react';
import { useNavigate } from 'react-router-dom';
import { dayjsGBLocale } from 'src/utils/dayjsWithLocale';

import { Text } from '@consta/uikit/Text';
import { Badge } from '@consta/uikit/Badge';
import { routerConstants } from 'src/core/routes/constants';
import { NotificationTypeENUM, type NotificationItemType } from '../types';

import styles from './NotificationItem.module.scss';

type PropsType = NotificationItemType;

const NotificationItem: React.FC<PropsType> = (props) => {
  const navigate = useNavigate();

  const dateString = React.useMemo(() => {
    return dayjsGBLocale(props.date).format('MMM D · hh:mm A');
  }, [props.date]);

  const handleClick = () => {
    const address = props.type === NotificationTypeENUM.kyc ? `${routerConstants.users}?s=${props.id}` : `${routerConstants.support}?chatId=${props.id}`;
    navigate(address);
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.container__row}>
        <Text view="link" className={styles.text__label}>
          {props.label}
        </Text>

        <Text
          view="brand"
          size="s"
          lineHeight="xs"
        >
          {dateString}
        </Text>
      </div>

      <div className={styles.container__row}>
        <Text className={styles.text__description}>
          {props.description}
        </Text>

        {props.type === NotificationTypeENUM.kyc && (
          <Badge
            size="s"
            form="default"
            className={styles.verification_badge}
            label="Verification Request"
          />)}

        {props.type === NotificationTypeENUM.report && (
          <Badge
            size="s"
            form="default"
            label="PENDING"
            className={styles.pending_badge}
          />)}
      </div>
    </div>
  );
};

export default NotificationItem;
